require([
    'jquery',
    'jquery.rwdImageMaps',
    'jquery.mobile'
], function($) {
    $(document).ready(function() {
        $('img[usemap]').rwdImageMaps();


        var $locationList = $('.location-list');

        $locationList.swipeleft(function() {
            var $first = $locationList.first();
            var $last = $locationList.last();

            $first.removeClass('visible').addClass('left');
            $last.removeClass('right').addClass('visible');
        });

        $locationList.swiperight(function() {
            var $first = $locationList.first();
            var $last = $locationList.last();

            $first.removeClass('left').addClass('visible');
            $last.removeClass('visible').addClass('right');
        });
    });
});